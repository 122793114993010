<template>
    <div>
        <t_header :isSort="isSort" @setTodos="getTodos"></t_header>
        <div class="htmldiv" v-html="text" style="width:1200px;margin:0 auto;text-align:left;"></div>
        <t_footer></t_footer>
    </div>
</template>

<script>
import t_header from '@/components/t_header/index.vue';
import t_footer from '@/components/t_footer/index.vue'
import { information_content,information_content2 } from "@/api/myself";

export default {
  name: 'html',
  data(){
    return{
      text:'',
      type:0,
      isUserInfo:false,
    }
  },
  components: {
    t_header,
    t_footer
  },
  mounted(){
    console.log(this.$route.query);
    
    var typeName = this.$route.query.name;
    var id = this.$route.query.id;
    if(id != null && id !=''){
      var obj={coursePurchaseAgreementId:id}
      this.information_content2(obj);
    }
    if(typeName != null && typeName !=''){
      var obj={typeName:typeName}
      this.information_content(obj);
    }
  },
  methods:{
    // 父传子
      getTodos(e){
          console.log(e)
          this.isUserInfo=e
      },
    //  用户协议与隐私政策
    information_content(obj){
      information_content(obj).then((res)=>{
        this.text = res.data.data
      })
    },
    information_content2(obj){
      information_content2(obj).then((res)=>{
        console.log(res)
        this.text = res.data
      })
    }
    
  }
}
</script>
<style>
  .htmldiv img{
    width: 100%;
    height: 100%;
  }

  .htmldiv{
        padding-left:20px;
        padding-right:20px;
        padding-top:100px;
        padding-bottom:100px;
        font-size: 16px;
        line-height: 27px;
        color: #50505a;
        word-wrap: break-word;
        min-height: 400px;;
        /* margin-top:200px; */
        /* margin-bottom:80px; */
    }
    .htmldiv p{
        text-align:left;
        padding-bottom:10px;
        color:#50505a;
        font-family: Helvetica Neue,Helvetica,Arial,Hiragino Sans GB,Microsoft Yahei,STXihei,SimHei,WenQuanYi Micro Hei,Droid Sans,sans-serif;
    }
    .htmldiv h1{
        text-align:left;
        padding-bottom:10px;
        color:#50505a;
        font-family: Helvetica Neue,Helvetica,Arial,Hiragino Sans GB,Microsoft Yahei,STXihei,SimHei,WenQuanYi Micro Hei,Droid Sans,sans-serif;
    }

    .htmldiv img{
        width: 100%;
        display: block;
        pointer-events: auto;
    }
</style>
